<template>
  <div class="footer-bar">
    <div class="left" @click="writeReply">
      <input type="text" disabled="disabled" placeholder="说点什么吧..." />
      <i class="jym-icon-test13"></i>
      <!-- <i @click="send" class="jym-fasong"></i> -->
    </div>
    <div class="right">
      <ul>
        <li>
          <i @click="comment" class="jym-pinglun1"></i>
        </li>
        <li>
          <i
            @click="collect(is_collect === 1 ? 0 : 1)"
             :class="is_collect === 1 ? 'jym-shoucang1 orange' : 'jym-shoucang'"
          ></i>
        </li>
        <li>
          <i
            @click="praise(is_praise === 1 ? 0 : 1)"
            :class="is_praise === 1 ? 'jym-dianzan3 orange' : 'jym-dianzan4'"
          ></i>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["is_praise", "is_collect"],
  data() {
    return {
      // comment: ""
    };
  },
  methods: {
    // send() {
    //   this.$emit("submitComment", this.comment, "first");
    // },
    comment() {
      this.$emit("comment")
    },
    praise() {
      this.$emit("communityPraise", this.is_praise);
    },
    collect() {
      this.$emit("communityCollect", this.is_collect);
    },
    writeReply() {
      this.$emit('writeReply')
    }
  }
};
</script>

<style lang="scss" scoped>
.footer-bar {
  height: 0.88rem;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  background: white;
  display: flex;
  justify-content: space-between;
  .left {
    position: relative;
    input {
      background: #f7f7f7;
      height: 0.6rem;
      width: 3.2rem;
      border-radius: 0.32rem;
      margin: 0.14rem 0.2rem;
      font-size: 0.24rem;
      text-indent: 0.24rem;
    }
    i {
      font-size: 0.36rem;
      position: absolute;
      top: 0.24rem;
      right: 0.36rem;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    ul {
      display: flex;
      flex-flow: row nowrap;
      li {
        width: 1rem;
        position: relative;
        i {
          font-size: 0.42rem;
        }
      }
    }
  }
}
</style>
