<template>
  <div class="community">
    <div class="container" :key="index">
      <div class="title">
        <div>
          <img :src="detail.avatar" />
          <p>
            <strong>{{ detail.nickname }}</strong>
            <span>{{ detail.create_time }}</span>
          </p>
        </div>
        <div class="share" @click="care">{{ status != 1 ? '取消关注': '＋ 关注' }}</div>
      </div>

      <div class="content">
        <p v-html="detail.content"></p>

        <div v-if="detail.type == 1 && detail.media.length">
          <div v-if="detail.media.length == 1" class="figure-list one">
            <img
              v-for="(src, i) in detail.media"
              :key="i"
              @click="ImagePreview(detail.media, i)"
              v-lazy="src"
            />
          </div>
          <div
            v-else-if="detail.media.length % 2 == 0 && detail.media.length < 6"
            class="figure-list two"
          >
            <div class="img-container">
              <img
                v-for="(src, i) in detail.media"
                :key="i"
                @click="ImagePreview(detail.media, i)"
                v-lazy="src"
              />
            </div>
          </div>
          <div v-else class="figure-list three">
            <div class="img-container">
              <img
                v-for="(src, i) in detail.media"
                :key="i"
                @click="ImagePreview(detail.media, i)"
                v-lazy="src"
              />
            </div>
          </div>
        </div>
        <div class="video" v-if="detail.type == 2">
          <video :src="detail.media" width="100%" height="100%" controls></video>
        </div>
      </div>
    </div>

    <van-image-preview
      v-if="imageShow"
      v-model="imageShow"
      :images="images"
      :showIndex="false"
      :startPosition="startPosition"
      :showIndicators="true"
      @close="vanClose"
    ></van-image-preview>

    <div class="comment" ref="container">
      <div class="tips" id="comment">
        <span>评论({{ comment_num }})</span>
      </div>

      <div
        class="lesson-list"
        v-infinite-scroll="loadMore"
        infinite-scroll-disabled="loading"
        infinite-scroll-distance="10"
      >
        <div class="main" v-if="commentList.length > 0">
          <ul class="main-container">
            <li v-for="(item, index) in commentList" :key="index">
              <div class="user-img">
                <img :src="item.avatar" />
              </div>
              <div class="user-box">
                <div class="title">
                  <span class="name">{{ item.nickname }}</span>
                  <div>
                    <em>{{ item.praise_num ? item.praise_num : '' }}</em>
                    <i
                      @click="praise(item.id, item.is_praise)"
                      :class="item.is_praise != 0 ? 'jym-dianzan3 orange' : 'jym-dianzan4'"
                    ></i>
                  </div>
                </div>
                <div class="user-content">
                  <p
                    @click="commentReply(item.nickname, item.id, item.to_uid, index)"
                  >{{ item.comment }}</p>
                </div>

                <div class="time-reply">
                  <div>
                    <time>
                      {{ item.create_time }}
                      <i>·</i>
                    </time>
                    <span
                      @click="isShowReply([item.nickname, item.id, item.to_uid, index], item.comment_num)"
                      :class="{'have-reply': item.comment_num > 0}"
                    >
                      <em v-if="item.comment_num > 0">{{ item.comment_num }}</em>回复
                    </span>
                  </div>
                  <i
                    v-if="uid === item.uid"
                    @click="delReply(item.id, index)"
                    class="del jym-shanchu"
                  ></i>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div v-else class="emptyData" v-show="initLoading">
          <img src="@assets/images/emptyData.png" />
          <p>暂无评论</p>
        </div>

        <Loading :loaded="loadend" :loading="loading"></Loading>
      </div>
    </div>

    <!-- 删除评论 -->
    <mt-popup v-model="delVisible" position="bottom">
      <del-comment @del="del" @cancel="cancel"></del-comment>
    </mt-popup>

    <!-- 回复评论 -->
    <mt-popup v-model="replyVisible" position="bottom">
      <reply-comment
        :key="timer"
        :replyLevel="replyLevel"
        :nickname="nickname"
        @reply="submitComment"
      ></reply-comment>
    </mt-popup>

    <!-- 二级回复列表 -->
    <mt-popup v-model="listVisible" position="bottom">
      <reply-list
        ref="replyList"
        :key="timer"
        :id="id"
        :reply_info="reply_info"
        @praise="praise"
        @del="delReply"
        @reply="commentReply"
        @closeReply="closeReply"
      ></reply-list>
    </mt-popup>

    <!-- 底部导航 -->
    <comment-footer
      :is_praise="is_praise"
      :is_collect="is_collect"
      @comment="comment"
      @writeReply="writeReply"
      @communityCollect="communityCollect"
      @communityPraise="communityPraise"
      @submitComment="submitComment"
    ></comment-footer>
  </div>
</template>

<script>
import delComment from "@views/community/delComment";
import replyComment from "@views/community/replyComment";
import replyList from "@views/community/replyList";
import { getUser } from "@api/user";
import CommentFooter from "@views/community/commentFooter";
import Loading from "@components/Loading";
import {
  getCommunityDetail,
  getCommunityCare,
  getCommunityComment,
  commentPraise,
  communityPraise,
  communityComment,
  communityDelComment,
  communityCollect,
} from "@api/community";

export default {
  name: "community-list",
  components: { CommentFooter, delComment, replyComment, replyList, Loading },
  data() {
    return {
      id: "",
      uid: "",
      status: "",
      comment_num: "",
      detail: {},
      commentList: [],
      is_collect: "",
      is_praise: "",
      delVisible: false,
      replyVisible: false,
      listVisible: false,
      commentId: "",
      level: "",
      index: "",
      nickname: "",
      pid: "",
      to_uid: "",
      page: 1,
      limit: 10,
      loading: false,
      loadend: false,
      timer: "",
      replyLevel: "",
      level: "",
      reply_info: {},
      imageShow: false,
      images: [],
      initLoading: false,
      startPosition: 0,
      addReply: {
        avatar: "",
        child_comment: [],
        comment: "",
        comment_num: 0,
        community_id: "",
        create_time: "1分钟前",
        id: "",
        is_praise: 0,
        is_read: 0,
        nickname: "",
        pid: "",
        praise_num: "",
        real_name: "",
        to_nickname: "",
        to_uid: "",
        uid: "",
      },
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.User();
    this.getDetail();
  },
  mounted() {
    this.loadMore();
    this.$scroll(this.$refs.container, () => {
      !this.loading && this.loadMore();
    });
  },
  methods: {
    // 关闭图片预览
    vanClose() {
      this.images = [];
      this.imageShow = false;
    },

    // 图片预览
    ImagePreview(images, index) {
      this.images = images;
      this.imageShow = true;
      this.startPosition = index;
    },

    // 获取用户信息
    User() {
      getUser().then((res) => {
        let that = this,
          data = res.data;

        that.uid = data.uid;
        that.addReply.uid = data.uid;
        that.addReply.to_uid = data.uid;
        that.addReply.avatar = data.avatar;
        that.addReply.nickname = data.nickname;
      });
    },

    // 获取详情
    getDetail() {
      let that = this;

      getCommunityDetail(that.id).then((res) => {
        that.$set(that, "detail", res.data);
        that.$set(that, "status", res.data.is_care == 1 ? 0 : 1);
        that.$set(that, "comment_num", res.data.comment_num);
        that.$set(that, "is_praise", res.data.is_praise);
        that.$set(that, "is_collect", res.data.is_collect);
      });
    },

    //获取评论
    loadMore() {
      let that = this;

      if (that.loading) return; //阻止下次请求（false可以进行请求）；
      if (that.loadend) return; //阻止结束当前请求（false可以进行请求）；
      that.loading = true;
      getCommunityComment(that.id, this.page, that.limit, 0).then((res) => {
        that.loading = false;
        //apply();js将一个数组插入另一个数组;
        that.commentList.push.apply(that.commentList, res.data);
        that.loadend = res.data.length < that.limit; //判断所有数据是否加载完成；
        that.page = that.page + 1;
        that.initLoading = true;
      });
    },

    // 删除评论显示弹窗
    delReply(id, index, level) {
      this.commentId = id;
      this.index = index;
      this.level = level;
      this.delVisible = true;
    },

    // 取消评论
    cancel() {
      this.delVisible = false;
    },

    // 删除评论
    del() {
      communityDelComment(this.commentId).then((res) => {
        let that = this;

        if (that.level == "second") {
          that.$refs.replyList.replyList.splice(that.index, 1);
          that.$set(that, "comment_num", --that.comment_num);
          that.$set(
            that.commentList[that.reply_info.index],
            "comment_num",
            --that.commentList[that.reply_info.index].comment_num
          );
        } else {
          that.$set(
            that,
            "comment_num",
            --that.comment_num - that.commentList[that.index].comment_num
          );
          that.commentList.splice(that.index, 1);
          that.$set(that, "commentList", that.commentList);
        }

        that.$dialog.success(res.msg);
        that.delVisible = false;
      });
    },

    // 评论回复弹窗
    commentReply(name, pid, to_uid, index) {
      // this.timer = new Date().getTime();
      this.nickname = name;
      this.pid = pid;
      this.to_uid = to_uid;
      this.index = index;
      this.replyLevel = 0;
      this.replyVisible = true;
    },

    // 获取评论焦点
    writeReply() {
      let that = this;
      that.timer = new Date().getTime();
      that.replyLevel = 1;
      that.replyVisible = true;
    },

    //提交评论
    submitComment(comment, level) {
      let that = this;
      let params = {
        community_id: that.id,
        pid: that.pid,
        comment: comment,
        to_uid: that.to_uid,
      };

      communityComment(params)
        .then((res) => {
          that.addReply.id = res.data.id;
          that.addReply.comment = comment;
          that.$set(that, "comment_num", ++that.comment_num);

          if (level == "first") {
            that.$set(that, "commentList", [
              Object.assign({}, that.addReply, { comment: comment }),
              ...that.commentList,
            ]);
          } else {
            that.timer = new Date().getTime();
            setTimeout(() => {
              that.$refs.replyList.loadMore();
            }, 0);
            that.$set(
              that.commentList[that.index],
              "comment_num",
              ++that.commentList[that.index].comment_num
            );
          }

          that.replyVisible = false;
          // that.$dialog.success(res.msg);
        })
        .catch((res) => {
          that.$dialog.error(res.msg);
        });
    },

    //关注 status 1关注，0取关
    care() {
      getCommunityCare({
        uid: this.id,
        status: this.status,
      })
        .then(() => {
          this.$set(this, "status", this.status == 1 ? 0 : 1);
        })
        .catch((err) => {
          this.$dialog.error(err.msg);
        });
    },

    // 回复评论
    isShowReply(params, num) {
      let that = this;
      if (num > 0) {
        that.timer = new Date().getTime();
        that.reply_info = that.commentList[params[3]];
        that.$set(
          that,
          "reply_info",
          Object.assign({}, that.reply_info, { index: params[3] })
        );
        that.listVisible = true;
        setTimeout(() => {
          that.$refs.replyList.loadMore();
        }, 0);
      } else {
        that.commentReply(...params);
      }
    },

    // 关闭回复
    closeReply() {
      this.listVisible = false;
    },

    // 评论列表点赞
    praise(id, status) {
      let that = this;
      commentPraise({ comment_id: id, status: status === 0 ? 1 : 0 }).then(
        () => {
          // 遍历列表，点赞加一，取消减一
          that.commentList.forEach((item, i) => {
            if (item.id === id) {
              if (item.is_praise === 0) {
                that.$set(
                  that.commentList[i],
                  "praise_num",
                  ++that.commentList[i].praise_num
                );
                that.$set(that.commentList[i], "is_praise", 1);
              } else {
                that.$set(
                  that.commentList[i],
                  "praise_num",
                  --that.commentList[i].praise_num
                );
                that.$set(that.commentList[i], "is_praise", 0);
              }
            }
            that.$set(that, "reply_info", that.commentList[i]);
          });
        }
      );
    },

    // 发现点赞
    communityPraise(status) {
      let that = this;

      communityPraise({
        community_id: that.id,
        status: status === 0 ? 1 : 0,
      }).then(() => {
        that.$set(that, "is_praise", status === 0 ? 1 : 0);
      });
    },

    // 收藏
    communityCollect(status) {
      let that = this;

      communityCollect({
        community_id: that.id,
        status: status === 0 ? 1 : 0,
      }).then(() => {
        that.$set(that, "is_collect", status === 0 ? 1 : 0);
      });
    },

    // 评论定位
    comment() {
      document.getElementById("comment").scrollIntoView();
    },
  },
};
</script>

<style lang="scss" scoped>
.community {
  padding-bottom: 0.88rem;
  .container {
    padding: 0.4rem 0 0.4rem 1.2rem;
    background: white;
    margin-bottom: 0.2rem;
    .figure-list {
      margin: 0;
      padding: 0;
    }
    .one {
      img {
        width: 3.6rem;
        height: 3.6rem;
        object-fit: cover;
      }
    }
    .two {
      .img-container {
        display: flex;
        flex-flow: row wrap;
        justify-content: start;
        margin: 0 -0.05rem;
        img {
          width: 2.4rem;
          height: 2.4rem;
          margin: 0.05rem;
          object-fit: cover;
        }
      }
    }
    .three {
      .img-container {
        display: flex;
        flex-flow: row wrap;
        margin: 0 -0.05rem;
        img {
          width: 1.9rem;
          height: 1.9rem;
          margin: 0.05rem;
          object-fit: cover;
        }
      }
    }
    .video {
      position: relative;
      width: 100%;
      height: 4rem;
      overflow: hidden;
      i {
        position: absolute;
        color: white;
        font-size: 0.6rem;
        z-index: 999;
        top: 1.7rem;
        left: 2.75rem;
      }
    }

    .title {
      height: 0.72rem;
      position: relative;
      img {
        width: 0.72rem;
        height: 0.72rem;
        border-radius: 100%;
        position: absolute;
        left: -0.9rem;
      }
      p {
        display: flex;
        flex-flow: column nowrap;
        strong {
          font-size: 0.28rem;
        }
        span {
          margin-top: 0.04rem;
          font-size: 0.2rem;
          color: #999;
        }
      }
      .share {
        position: absolute;
        top: 0.3rem;
        right: 0;
        font-size: 0.26rem;
        color: #aaa;
        border: 1px solid #f1f1f1;
        border-right: none;
        padding: 0.08rem 0.24rem;
        border-radius: 0.3rem 0 0 0.3rem;
        i {
          font-size: 0.2rem;
          margin-right: 0.1rem;
        }
      }
    }
    .content {
      font-size: 0.28rem;
      line-height: 0.48rem;
      margin: 0.2rem 0;
      padding: 0 0.4rem 0 0;
      p {
        overflow: hidden;
        margin-bottom: 0.1rem;
        white-space: pre-wrap;
      }
      span {
        color: #f60;
        cursor: pointer;
      }
    }
    .bottom {
      width: 100%;
      font-size: 0.24rem;
      color: #999;
      line-height: 0.52rem;
      float: right;
      padding-right: 0.4rem;
      display: flex;
      justify-content: space-between;
      i {
        margin-right: 0.06rem;
      }
    }
  }

  .comment {
    background: white;
    .tips {
      line-height: 0.8rem;
      border-bottom: 0.02rem solid #a9a9a9;
      padding: 0 0.3rem;
      span {
        font-size: 0.28rem;
        font-weight: 600;
        border-bottom: 0.04rem solid black;
        padding-bottom: 0.1rem;
      }
    }
    .main {
      padding: 0 0.3rem;
      overflow: scroll;
      padding-bottom: 1rem;
      ul.main-container > li {
        width: 100%;
        padding: 0.2rem 0;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        .user-img {
          width: 0.56rem;
          height: 0.56rem;
          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
        }
        .user-box {
          width: 100%;
          padding-left: 0.2rem;
          .title {
            display: flex;
            justify-content: space-between;
            .name {
              font-size: 0.26rem;
              font-weight: 600;
            }
            i {
              padding-left: 0.1rem;
              vertical-align: inherit;
            }
          }
          .user-content {
            font-size: 0.26rem;
            line-height: 0.36rem;
            padding: 0.1rem 0;
          }
          .time-reply {
            font-size: 0.24rem;
            display: flex;
            justify-content: space-between;
            time {
              display: inline;
              color: #a9a9a9;
              i {
                font-size: 0.32rem;
                padding: 0 0.05rem;
              }
            }
            span {
              font-size: 0.2rem;
            }
            .have-reply {
              background: #e5e5e5;
              padding: 0.06rem 0.12rem;
              border-radius: 0.2rem;
            }
            .del {
              float: right;
              font-size: 0.28rem;
            }
          }
        }
      }
    }
  }
  .mint-popup {
    width: 100%;
  }
}
</style>
