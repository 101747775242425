<template>
  <div class="main" ref="container">
    <h4>
      <i @click="close" class="jym-close"></i>回复详情
    </h4>
    <ul
      class="main-container"
      v-infinite-scroll="loadMore"
      infinite-scroll-distance="0"
      infinite-scroll-immediate-check="false"
    >
      <li>
        <div class="user-img">
          <img :src="reply_info.avatar" />
        </div>
        <div class="user-box">
          <div class="title">
            <span class="name">{{ reply_info.nickname }}</span>
            <!-- <div>
              <em>{{ reply_info.praise_num > 0 ?  reply_info.praise_num : ''}}</em>
              <i
                @click="praise(reply_info.id, reply_info.is_praise)"
                :class="reply_info.is_praise != 0 ? 'jym-dianzan3 orange' : 'jym-dianzan4'"
              ></i>
            </div> -->
          </div>
          <div class="user-content">
            <p>{{ reply_info.comment }}</p>
          </div>

          <div class="time-reply">
            <div>
              <time>{{ reply_info.create_time }}</time>
            </div>
          </div>
        </div>
      </li>
      <hr />
      <h5>全部回复</h5>
      <li v-for="(item, index) in replyList" :key="index">
        <div class="user-img">
          <img :src="item.avatar" />
        </div>
        <div class="user-box">
          <div class="title">
            <span class="name">{{ item.nickname }}</span>
            <div>
              <em>{{ item.praise_num > 0 ? item.praise_num : '' }}</em>
              <i
                @click="secondPraise(item.id, item.is_praise, index)"
                :class="item.is_praise != 0 ? 'jym-dianzan3 orange' : 'jym-dianzan4'"
              ></i>
            </div>
          </div>
          <div class="user-content">
            <p @click="reply([item.nickname, reply_info.id, item.to_uid, index])">
              <span>回复<em>{{ item.to_nickname }}</em>：</span>{{ item.comment }}
            </p>
          </div>

          <div class="time-reply">
            <div>
              <time>
                {{ item.create_time }}
                <i>·</i>
              </time>
              <span  @click="reply([item.nickname, reply_info.id, item.to_uid, index])">
                回复
              </span>
            </div>
            <i  v-if="uid === item.uid" class="del jym-shanchu" @click="delReply(item.id, index)"></i>
          </div>
        </div>
      </li>
    </ul>
    
    <Loading :loaded="loadend" :loading="loading"></Loading>
  </div>
</template>

<script>
import { getUser } from "@api/user";
import Loading from "@components/Loading";
import {
  getCommunityComment,
  commentPraise
} from "@api/community";

export default {
  props: ["id", "reply_info"],
  components: {
    Loading
  },
  data() {
    return {
      uid: "",
      commentId: "",
      index: "",
      page: 1,
      limit: 10,
      pid: "",
      replyList: [],
      loadend: false,
      loading: false
    };
  },
  created() {
    this.pid = this.reply_info.id;
    this.User();
  },
  methods: {
    // 获取用户信息
    User() {
      getUser().then(res => {
        this.uid = res.data.uid
      })
    },

    //获取评论
    loadMore() {
      let that = this;

      if (that.loading) return; //阻止下次请求（false可以进行请求）；
      if (that.loadend) return; //阻止结束当前请求（false可以进行请求）；
      that.loading = true;
      getCommunityComment(that.id, that.page, that.limit, that.reply_info.id).then(res => {
        that.loading = false;
        //apply();js将一个数组插入另一个数组;
        that.replyList.push.apply(that.replyList, res.data);
        that.loadend = res.data.length < that.limit; //判断所有数据是否加载完成；
        that.page = that.page + 1;
      });
    },

    // 回复评论
    reply(params) {
      this.$emit('reply', ...params);
    },

    // 删除评论显示弹窗
    delReply(id, index) {
      let that = this;
      that.commentId = id;
      that.index = index;

      that.$emit('del', id, index, 'second')
    },

    // 点赞
    praise(id, status) {
      this.$emit("praise", id, status);
    },

    // 二级点赞
    secondPraise(id, status, i) {
      let that = this;
      let ele = that.replyList[i];

      commentPraise({ comment_id: id, status: status === 0 ? 1 : 0 }).then(
        () => {
          // 遍历列表，点赞加一，取消减一
          if (ele.is_praise === 0) {
            that.$set(ele, "praise_num", ++ele.praise_num);
            that.$set(ele, "is_praise", 1);
          } else {
            that.$set(ele, "praise_num", --ele.praise_num);
            that.$set(ele, "is_praise", 0);
          }
        }
      );
    },

    // 关闭弹窗
    close() {
      this.$emit("closeReply");
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  overflow: scroll;
  padding-bottom: 1rem;
  position: relative;
  height: 9rem;
  h4 {
    height: auto;
    width: 100%;
    font-size: 0.28rem;
    text-align: center;
    font-weight: 500;
    padding: 0.3rem;
    position: fixed;
    background: white;
    top: 0;
    left: 0;
    i {
      float: left;
      font-size: 0.42rem;
    }
  }
  ul {
    padding-top: 0.88rem;
    hr {
      border: none;
      height: 1px;
      background-color: #e5e5e5;
      margin: 0.2rem 0;
    }
    h5 {
      font-size: 0.26rem;
      line-height: 0.5rem;
      font-weight: 500;
      padding-left: 0.3rem;
    }
    li {
      width: 100%;
      padding: 0.2rem 0.3rem;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      .user-img {
        width: 0.56rem;
        height: 0.56rem;
        img {
          width: 100%;
          height: 100%;
          border-radius: 100%;
        }
      }
      .user-box {
        width: 100%;
        padding-left: 0.2rem;
        .title {
          display: flex;
          justify-content: space-between;
          .name {
            font-size: 0.26rem;
            font-weight: 600;
          }
          i {
            padding-left: 0.1rem;
            font-size: 0.32rem;
            vertical-align: inherit;
          }
        }
        .user-content {
          font-size: 0.26rem;
          line-height: 0.36rem;
          padding: 0.1rem 0;
          span {
            em {
              margin: 0 .06rem;
              color: #2291f8;
            }
          }
        }
        .time-reply {
          font-size: 0.24rem;
          display: flex;
          justify-content: space-between;
          time {
            display: inline;
            color: #a9a9a9;
            i {
              font-size: 0.32rem;
              padding: 0 0.05rem;
            }
          }
          span {
            font-size: 0.2rem;
          }
          .del {
            float: right;
            font-size: 0.28rem;
          }
        }
      }
    }
  }
  .mint-popup {
    width: 100%;
  }
}
</style>
