<template>
  <div class="reply">
    <textarea v-if="replyLevel == 1" name="reply" ref="input" v-model="comment" autofocus maxlength="200" placeholder="请输入一条热评"></textarea>
    <textarea v-else name="reply" v-model="comment" ref="input" autofocus maxlength="200" :placeholder="'回复' +nickname+ '：'"></textarea>
    <button type="text" @click="reply" :class="{active: isShow}">发表</button>
  </div>
</template>

<script>
export default {
  props: ['nickname', 'replyLevel'],
  data() {
    return {
      comment: '',
      isShow: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.input.focus();
    })
  },
  watch: {
    comment(val) {
      !val ? this.isShow = false : this.isShow = true;
    },  
  },
  methods: {
    reply() {
      this.$emit('reply', this.comment, this.replyLevel == 1 ? 'first' : 'second')
    }
  }
}
</script>

<style lang="scss" scoped>
.reply {
  width: 100%;
  height: auto;
  background: white;
  padding: .2rem .3rem;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  textarea {
    width: 5.5rem;
    height: 0.6rem;
    background: #f7f7f7;
    font-size: .26rem;
    color: black;
    padding: 0.1rem .2rem;
  }
  button {
    width: 1.2rem;
    height: .56rem;
    background: #E8ECEF;
    color: #bbb;
    font-size: .26rem;
    border-radius: .02rem;
  }
  .active {
    background: #daa070;
    color: white;
  }
}
</style>
