<template>
  <div class="del">
    <ul>
      <li>确认删除该评论？</li>
      <li @click="del">确认删除</li>
      <li @click="cancel">取消</li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods: {
    del() {
      this.$emit('del')
    },
    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="scss" scoped>
.del {
  width: 100%;
  height: auto;
  background: white;
  ul{
    li {
      font-size: .28rem;
      text-align: center;
      font: .28rem/1rem 'microsoft yahei';
    }
    li:first-child {
      font-size: .26rem;
      color: #666;
      border-bottom: .02rem solid #f5f5f5;
    }
    li:nth-child(2) {
      color: #ea1e1e;
    }
    li:last-child {
      border-top: .2rem solid #f5f5f5;
    }
  }
}
</style>
